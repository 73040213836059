<template>
  <div class="container">
    <nav-bar headerTitle="神秘顾客征集中" :showBack="true" :header-back="appBack"></nav-bar>
    <div class="co">
      <img src="https://img.chaolu.com.cn/ACT/mystery-customer/1.jpg" alt="">
      <img src="https://img.chaolu.com.cn/ACT/mystery-customer/2.jpg" alt="">
      <img src="https://img.chaolu.com.cn/ACT/mystery-customer/3.jpg" alt="">
      <img @click="goWeb" src="https://img.chaolu.com.cn/ACT/mystery-customer/4.jpg" alt="">
      <img src="https://img.chaolu.com.cn/ACT/mystery-customer/5.jpg" alt="">
    </div>

  </div>
</template>

<script>
import appMixin from '@/mixin/appMixin'
import { appBack, defaultApp } from "@/lib/appMethod"
import navBar from '@/components/nav-bar/nav-bar'
export default {
  data() {
    return {

    };
  },
  mixins: [appMixin],
  components: {
    navBar,
  },
  created() {

  },
  methods: {
    appBack() {
      appBack();
    },
    goWeb() {
      if (this.appTypeStr === 'mini') {
        this.$router.push("/emptyPage?isUndo=1")
      } else {
        defaultApp();
        window.location.href = 'https://superdeer.wjx.cn/vm/P678vXD.aspx'
      }
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  img {
    width: 100%;
    display: block;
    margin: -1px 0 0;
  }
}
</style>
