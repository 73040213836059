<template>
  <div class="page-anniversary-2023">
    <headBar left-arrow :color="header_color" :title="header_title" :background="header_background"
      :opacity="header_opacity" :placeholder="false" @click-left="newAppBack" @setBarHeitht="setBarHeitht" />
    <div class="headbg">
      <img src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_banner.png" class="head-img" />
      <div class="rs">
        <div class="sec1" @click="showShare = true">分享</div>
        <div class="sec2" @click="ruleKey = undefined, showRule = true">规则</div>
      </div>
    </div>
    <div class="cc-content col-start-center">
      <div class="timer">
        {{ status == 'notin' ? '距活动开始' : status == 'in' ? '距活动结束' : status == 'end' ? '活动已结束' : '' }}
        <template v-if="status == 'notin' || status == 'in'">
          <van-count-down @finish="timeFinish" :time="time">
            <template #default="timeData">
              <div class="tbox">{{ timeData.days }}</div>天
              <div class="tbox">{{ timeData.hours }}</div>时
              <div class="tbox">{{ timeData.minutes }}</div>分
              <div class="tbox">{{ timeData.seconds }}</div>秒
            </template>
          </van-count-down>
        </template>
      </div>
      <div class="invite-teacher" v-if="inviteUserInfo.shareTeacherId">
        <div class="invite-head"><img :src="`${inviteUserInfo.shareUserHeadImg}`" alt="" />
        </div>
        <div class="invite-name ellipsis"> <span>{{ inviteUserInfo.shareTitle }}</span> 邀请你参与超鹿月中福利大放送</div>
      </div>

      <img @click="toPath('/temp-activity/15th-fission')"
        src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_banner_share.png" alt="" class="small-banner">
      <img @click="toPath2"
        src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_banner_student.png" alt=""
        class="small-banner">

      <img src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_title.png" class="goods-title" />
      <van-sticky style="width: 100%" :offset-top="barHeight" ref="jb">
        <div class="tofu" ref="tofu">
          <div class="tofu-top">
            <div class="tm mr" :class="{ active: activeKey == 2 }" @touchend="setActiveKey(2)">
              <div class="it">通用礼品卡</div>
              <div class="ii">最多赠送2700元</div>
            </div>
            <div class="tm" :class="{ active: activeKey == 3 }" @touchend="setActiveKey(3)">
              <div class="it">私教课包</div>
              <div class="ii">低至8.1折</div>
            </div>
          </div>
          <div class="tofu-bottom">
            <div class="tm" :class="{ active: activeKey == 4 }" @touchend="setActiveKey(4)">
              <div class="it">团课课包</div>
              <div class="ii">低至8折</div>
            </div>
            <div class="tm mlr" :class="{ active: activeKey == 1 }" @touchend="setActiveKey(1)">
              <div class="it">小班课课包</div>
              <div class="ii">买10节送2节</div>
            </div>
            <div class="tm" :class="{ active: activeKey == 5 }" @touchend="setActiveKey(5)">
              <div class="it">小班课礼品卡</div>
              <div class="ii">加赠自助月卡</div>
            </div>
          </div>
        </div>
      </van-sticky>
      <!-- 礼品卡 -->
      <template v-if="activeKey == 2">
        <div class="titleline">
          <img class="classimg" src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_title_gift_card.png" />

          <div class="row-start-center" @click="ruleKey = 2, showRule = true">
            <span class="ss">规则</span>
            <img src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_to.png" alt="" class="arrow">
          </div>
        </div>
        <div class="goodsbox">

          <div class="basebg pd2" v-for="(item, key) in cardMap">
            <div class="goodsmodel-shu" v-for="citem in item">
              <div class="send" v-if="citem.hasBuyLimit">限购1张，不可转赠</div>
              <div class="shu-img">
                <img :src="citem.picUrl" />
              </div>
              <div class="shu-name ellipsis">{{ citem.itemName }}</div>
              <div class="invalid-time" v-if="citem.expiryDateDescribe">{{ citem.expiryDateDescribe }}</div>
              <div class="label-line">
                <div class="label-model" v-if="citem.discountRate">
                  {{ citem.discountRate }}折
                </div>
                <div class="label-model" v-if="citem.save">
                  {{ citem.save }}
                </div>
              </div>
              <div class="price-line">
                <span class="dl">¥</span>
                <span class="price">{{ citem.salePrice }}</span>
                <span class="oldprice">¥{{ citem.originalPrice }}</span>
              </div>
              <div class="buy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }" @click="buyCard(citem, 12)">{{
                buttonText(citem) }}</div>
            </div>
          </div>
        </div>

      </template>


      <!-- 团课 -->
      <template v-else-if="activeKey == 4">
        <div class="titleline">
          <img class="classimg" src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_title_group_class.png" />

          <div class="row-start-center" @click="ruleKey = 4, showRule = true">
            <span class="ss">规则</span>
            <img src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_to.png" alt="" class="arrow">
          </div>
        </div>
        <div class="goodsbox" style="margin-top: 4px;">
          <div class="basebg pd1" v-for="(item, key) in groupMap">
            <div class="coner">{{ key }}元团课课包</div>
            <div class="goodsmodel-heng" v-for="citem in item">
              <div class="send" v-if="citem.describe">{{ citem.describe }}</div>
              <div class="goods-img"><img :src="citem.picUrl" /></div>
              <div class="goods-info">
                <div class="goods-name ellipsis">{{ citem.itemName }}</div>
                <div class="invalid-time" v-if="citem.expiryDateDescribe">{{ citem.expiryDateDescribe }}
                </div>
                <div class="label-line">
                  <div class="label-model" v-if="citem.discountRate">
                    {{ citem.discountRate }}折
                  </div>
                  <div class="label-model" v-if="citem.save">
                    {{ citem.save }}
                  </div>
                </div>
                <div class="lastline">
                  <div class="price-line">
                    <span class="dl">¥</span>
                    <span class="price">{{ citem.salePrice }}</span>
                    <span class="oldprice">¥{{ citem.originalPrice }}</span>
                  </div>
                  <div class="sbuy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }" @click="buyCard(citem, 10)">{{
                    buttonText(citem) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </template>

      <!-- 私教 -->
      <template v-else-if="activeKey == 3">
        <div class="titleline">
          <img class="classimg" src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_title_private_coach.png" />

          <div class="row-start-center" @click="ruleKey = 3, showRule = true">
            <span class="ss">规则</span>
            <img src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_to.png" alt="" class="arrow">
          </div>
        </div>
        <div class="goodsbox" style="margin-top: 4px;">
          <!-- <div class="csbar">
            <div class="barmodel" :class="{ active: tabKey == index }" v-for="(item, index) in tabBar"
              @touchend="toTab(index)">{{ item }}元</div>
          </div> -->
          <div class="basebg pd1" v-for="(item, key, index) in sijiaoMap" :ref="'tab' + index">
            <div class="coner">{{ key }}元私教课包</div>
            <div class="goodsmodel-heng" v-for="citem in item">
              <div class="send" v-if="citem.describe">{{ citem.describe }}</div>
              <div class="goods-img"><img :src="citem.picUrl" /></div>
              <div class="goods-info">
                <div class="goods-name ellipsis">{{ citem.itemName }}</div>
                <div class="invalid-time" v-if="citem.expiryDateDescribe">{{ citem.expiryDateDescribe }}
                </div>
                <div class="label-line">
                  <div class="label-model" v-if="citem.discountRate">
                    {{ citem.discountRate }}折
                  </div>
                  <div class="label-model" v-if="citem.save">
                    {{ citem.save }}
                  </div>
                </div>
                <div class="lastline">
                  <div class="price-line">
                    <span class="dl">¥</span>
                    <span class="price">{{ citem.salePrice }}</span>
                    <span class="oldprice">¥{{ citem.originalPrice }}</span>
                  </div>
                  <div class="sbuy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }" @click="buyCard(citem, 10)">{{
                    buttonText(citem) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- 小班课课包 -->
      <template v-if="activeKey == 1">
        <div class="titleline">
          <img class="classimg" src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_title_class.png" />

          <div class="row-start-center" @click="ruleKey = 1, showRule = true">
            <span class="ss">规则</span>
            <img src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_to.png" alt="" class="arrow">
          </div>
        </div>
        <div class="goodsbox">
          <div class="basebg">
            <div class="goodsmodel-heng" v-for="(citem, index) in microList">
              <div class="send" v-if="citem.describe">{{ citem.describe }}</div>
              <div class="goods-img big"><img :src="citem.picUrl" /></div>
              <div class="goods-info">
                <div class="goods-name ellipsis">{{ citem.itemName }}</div>
                <div class="invalid-time" v-if="citem.expiryDateDescribe">{{ citem.expiryDateDescribe }}
                </div>
                <div class="label-line">
                  <div class="label-model" v-if="citem.save">
                    {{ citem.save }}
                  </div>
                </div>
                <div class="lastline">
                  <div class="price-line">
                    <span class="dl">¥</span>
                    <span class="price">{{ citem.salePrice }}</span>
                    <span class="oldprice">¥{{ citem.originalPrice }}</span>
                  </div>
                  <div class="sbuy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }" @click="buyCard(citem, 10)">{{
                    buttonText(citem) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </template>

      <!--小班课礼品卡 -->
      <template v-else-if="activeKey == 5">
        <div class="titleline">
          <img class="classimg" src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_title_card.png" />

          <div class="row-start-center" @click="ruleKey = 5, showRule = true">
            <span class="ss">规则</span>
            <img src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_to.png" alt="" class="arrow">
          </div>
        </div>
        <div class="goodsbox">
          <div class="basebg pd2">
            <img src="https://img.chaolu.com.cn/ACT/anniversary-2023/micro12.png" class="micro12" />
            <div class="goodsmodel-shu" v-for="(citem, index) in xbklpkList">
              <div class="shu-img">
                <img :src="citem.picUrl" />
              </div>
              <div class="shu-name ellipsis">{{ citem.itemName }}</div>
              <div class="invalid-time" v-if="citem.expiryDateDescribe">{{ citem.expiryDateDescribe }}</div>
              <div class="label-line">
                <div class="label-model" v-if="citem.save">
                  {{ citem.save }}
                </div>
              </div>
              <div class="price-line">
                <span class="dl">¥</span>
                <span class="price">{{ citem.salePrice }}</span>
                <!-- <span class="oldprice">¥{{ citem.originalPrice }}</span> -->
              </div>
              <div class="buy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }" @click="openBuyCity(12, citem)">{{
                buttonText(citem) }}</div>
            </div>
          </div>
        </div>

      </template>

      <div class="end">已经滑到底部啦</div>

    </div>
    <van-popup v-model="showRule" position="bottom" safe-area-inset-bottom class="pop-rule">
      <div class="pop-rule-title">
        活动规则
        <van-icon name="cross" class="pop-rule-close" color="#fff" @click="showRule = false" />
      </div>
      <div class="pop-rule-content">
        <template v-if="!ruleKey">
          <div>
            1、活动时间：6月15日10:00-6月16日23:59:59<br>
            2、超鹿品牌日活动商品，购买后不支持退款。<br>
            3、活动商品，均设置有效期，请在有效期内尽快使用，逾期失效。<br>
            4、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。<br>
          </div>
        </template>
        <template v-if="ruleKey == 5 || !ruleKey">
          <div>
            <p style="text-align: center;">—— 小班课礼品卡规则 ——</p>
            1、小班课礼品卡仅限小班课产品使用。<br />
            2、小班课礼品卡未使用前可转赠给好友，但赠送福利部分不允许转赠，仅限用户自己使用。赠送部分：1张自助月卡。<br>
            3、小班课礼品卡不可与优惠券、余额同时使用。<br />
            <p v-show="ruleKey">4、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。</p>
          </div>
        </template>
        <div v-if="ruleKey == 1 || !ruleKey">
          <p style="text-align: center;">—— 小班课课包规则 ——</p>
          1、小班课课包券可转赠给好友；但一张券只允许转赠一次，不可二次流转。<br />
          2、小班课课包券不可与礼品卡、其他优惠券、余额同时使用。<br />
          <p v-show="ruleKey">3、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。</p>
        </div>
        <template v-if="ruleKey == 2 || !ruleKey">
          <div>
            <p style="text-align: center;">—— 通用礼品卡规则 ——</p>
            1、通用礼品卡，可用于按时计费的自助，按次单节购买私教、团课以及小班课等产品（除课包形式的产品）。<br />
            2、通用礼品卡不限购买次数，购买后未经使用前可转赠给好友，使用后不可转赠。<br />
            3、礼品卡不可与优惠券、余额同时使用。
            <p v-show="ruleKey">4、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。</p>
          </div>
        </template>
        <div v-if="ruleKey == 3 || !ruleKey">
          <p style="text-align: center;">—— 私教课包规则 ——</p>
          1、私教课包券可转赠给好友；但一张券只允许转赠一次，不可二次流转。<br />
          2、私教课包券不可与礼品卡、其他优惠券、余额同时使用。<br />
          <p v-show="ruleKey">3、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。</p>
        </div>
        <div v-if="ruleKey == 4 || !ruleKey">
          <p style="text-align: center;">—— 团课课包规则 ——</p>
          1、团课课包券可转赠给好友；但一张券只允许转赠一次，不可二次流转。<br />
          2、团课课包券不可与礼品卡、其他优惠券、余额同时使用。<br />
          <p v-show="ruleKey">3、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。</p>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showCity" position="bottom" safe-area-inset-bottom class="popcity">
      <div class="buy-title">
        确认下单
        <van-icon name="cross" class="buy-close" @click="showCity = false" />
      </div>
      <div class="cbox">
        <div class="buy-title2">购买商品</div>
        <div class="goods-line">
          <img :src="buyItem.picUrl" class="goods-img" />
          <div class="goods-info">
            <div class="goods-name">{{ buyItem.itemName }}</div>
            <div class="time" v-if="buyItem.expiryDateDescribe">{{ buyItem.expiryDateDescribe }}</div>
            <div class="label" v-if="buyItem.save">{{ buyItem.save }}</div>
          </div>
        </div>
        <div class="buy-title2">选择{{ buyType == 14 ? '购买卡城市' : '赠品自助月卡城市' }}</div>
        <div class="buy-tips">选择指定城市卡，选择后无法修改、退换。</div>
        <div class="city-list">
          <div v-for="(item, index) in cityList" :key="index" class="city-model"
            :class="{ 'city-active': cityIndex === index }" @click="cityIndex = index">
            {{ item.name }}
            <div class="checkbox"></div>
          </div>

        </div>
      </div>
      <div class="next" @click="showSure = cityIndex != undefined ? true : false">下一步</div>
    </van-popup>
    <van-popup v-model="showSure" position="center" class="popsure">
      <div class="sure-title">提示</div>
      <div class="sure-content">您当前{{ buyType == 14 ? '购买的自助健身卡' : '选择的赠品自助月卡' }}，仅限<span v-if="cityIndex != undefined">{{
        cityList[cityIndex].name
      }}</span>市所有门店使用，请确认后再付款！</div>
      <div class="sure-sub" @click="next">我知道了</div>
    </van-popup>
    <common-share :value="showShare" :shareParams="shareParams" @close="showShare = false"
      @share-success="showShare = false" :shareItem="['posterfriend', 'postermessage', 'save']"
      :miniSharePoster="appTypeStr === 'mini'">
      <template slot="createDom">
        <div class="canvascss">
          <img src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_ppp.png" class="canvascss_bg" />

          <div class="canvascss_left">
            <img :src="userDetail.headImg" />
          </div>

          <div class="canvascss_name ellipsis">
            {{ userDetail.nickName }}
          </div>

          <div class="canvascss_right">
            <img v-if="userDetail.qrCode" :src="'data:image/png;base64,' +
              userDetail.qrCode
              " />
          </div>
        </div>
      </template>
    </common-share>

  </div>
</template>
<script>
import {
  newAppBack,
  initBack,
  appPay,
  appPaySuccess,
  defaultApp
} from '@/lib/appMethod';
import headBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
import headerMixin from '@/mixin/headerMixin';
import commonShare from '@/components/commonShare';
import wx from 'weixin-js-sdk';
const webUrl = `${window.location.origin}/#/temp-activity/15th-mall`;
const activityNo = '618Sale';
export default {
  components: {
    headBar,
    commonShare
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      barHeight: undefined,
      status: '',
      time: 0,
      activeKey: 2,
      shareParams: {
        title: '月中健身 福利大放送',
        miniImage:
          'https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_mini.png',
        path: webUrl + '&userId=1&token=1',
        miniProgramType:
          window.location.origin.indexOf('web.chaolu.com') > -1
            ? '0'
            : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      userDetail: {}, //分享的用户信息
      inviteUserInfo: {}, // 邀请人信息
      showShare: false,
      showRule: false,
      cardMap: {},//礼品卡
      groupMap: {},
      sijiaoMap: {},

      xbklpkList: [],// 小班课礼品卡
      tabKey: 0,
      ruleKey: undefined,
      cityList: [
        { name: '福州市' },
        { name: '厦门市' },
        { name: '广州市' }
      ],
      showCity: false,
      cityIndex: undefined,
      buyType: undefined,
      showSure: false,
      buyItem: {}
    }
  },
  computed: {
    tabBar() {
      const list = [];
      for (let i in this.sijiaoMap) {
        list.push(i);
      }
      return list
    }
  },
  watch: {
    activeKey: {
      handler(v) {
        if (v == 3) {//私教
          this.bindSC();
          this.tabKey = 0;
        } else {
          this.topList = [];
        }
        const ref = this.$refs.jb;
        console.log(ref.$el.offsetTop);
        setTimeout(() => {
          window.scrollTo({
            letf: 0,
            top: ref.$el.offsetTop - (this.barHeight || 0) + 1,
            behavior: 'smooth'
          });
        })
      },
      // immediate: true
    }
  },
  methods: {
    newAppBack,
    setActiveKey(v) {
      this.activeKey = v;
    },
    toPath(path) {
      this.countPoint(234, 324, 1702, this.userId)
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#${path}&userId=1&token=1&location=1`,
        })
      } else {
        this.$router.push(path)
      }
    },
    toPath2() {
      this.countPoint(234, 324, 1704, this.userId)
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=https://wxs.chaolu.com.cn/appWeb/courseActivityNew.html&userId=1&activityId=347`,
        })
      } else {
        defaultApp();
        window.location.href = `https://wxs.chaolu.com.cn/appWeb/courseActivityNew.html?userId=1&activityId=347`
      }

    },
    buttonText(item = {}) {
      const status = item.itemStatus;
      const limit = item.hasBuyLimit;
      const json = {
        'NOT_STARTED': '待开启',
        'EXPIRED': '已结束',
        'SOLD_OUT': '已售罄',
        'HAVE_BUY': '已购买',
        'CAN_BUY': '马上抢'
      }
      let text = json[status] || '马上抢';
      if (status == 'CAN_BUY' && limit) {
        text = '限量抢';
      }
      return text;
    },

    setBarHeitht(h) {
      this.barHeight = h;
    },
    openBuyCity(type, item) {
      if (this.status != 'in') {
        this.$toast('未在活动时间内')
        return;
      }
      if (item.itemStatus !== 'CAN_BUY') return;

      this.buyItem = item;
      this.buyType = type;//区分小班课还是自助卡
      this.itemIndex = item.unitPrice;//哪个价格卡
      this.cityIndex = undefined;
      this.showCity = true;
    },
    next() {
      this.showSure = false;
      this.showCity = false;
      const model = this.buyType == '12' ? this.xbklpkMap : '';

      if (this.cityIndex !== undefined && model) {
        console.log(model, this.itemIndex, this.cityIndex);
        const payitem = model[this.itemIndex][this.cityIndex];
        console.log(payitem)
        this.buyCard(payitem, this.buyType);
      }
    },
    bindSC() {
      setTimeout(() => {
        const jian = (this.barHeight || 0) + this.$refs.tofu.clientHeight;
        const list = [];
        for (let i = 0; i < this.tabBar.length; i++) {
          list.push(this.$refs['tab' + i][0].offsetTop - jian - 10)
        }
        this.topList = list;
      })
    },
    toTab(index) {
      window.scrollTo({
        letf: 0,
        top: this.topList[index] || 0,
        behavior: 'smooth'
      })
    },
    timeFinish() {
      if (this.status == 'in') {
        this.status = 'end';
      } else {
        this.getActivityTime();
      }
      this.getGoodList();
    },
    getBindInfo(activityNo) {
      this.$axios
        .post(this.baseURLApp + "/userDistribute/bindUser ", {
          hasWx: true,
          activityNo: activityNo,
          userId: this.userId,
          saleTeacherId: this.inviteTeacherId,
        })
        .then((res) => {
          const d = res.data;
          this.inviteUserInfo = d || {}
          this.inviteTeacherId = d && d.shareTeacherId
        });
    },
    getActivityTime() {
      this.$axios
        .post(this.baseURLApp + '/activity/getActivityTime', {
          activityNo: '618Sale',
          hasWx: true,
          userId: this.userId,
        })
        .then((res) => {
          const d = res.data;
          this.status = d.diffStartTime > 0 ? 'notin' : d.diffEndTime > 0 ? 'in' : 'end'
          this.time = d.diffStartTime || d.diffEndTime;
        });
    },

    getGoodList() {
      this.$axios.post(`${this.baseURLApp}/anniversary/sale/getItemList`, {
        activityNo,
        userId: this.userId,
        hasWx: true
      }).then(res => {
        const r = res.data;
        const xbklpkList = [];
        const micro = [];
        for (let i in r.equityCardMap) {
          xbklpkList.push(r.equityCardMap[i][0]);
        }
        for (let i in r.microCoursePackageMap) {
          micro.push(r.microCoursePackageMap[i][0]);
        }
        console.log(micro);
        this.cardMap = r.cardMap;  //  礼品卡
        this.microList = micro;
        this.xbklpkList = xbklpkList; // 小班课礼品卡
        this.xbklpkMap = r.equityCardMap;
        this.groupMap = r.scheduleCoursePackageMap;  // 团课
        this.sijiaoMap = r.perCoursePackageMap;  // 私教
      })
    },
    async buyCard(item, type) {
      if (this.status != 'in') {
        this.$toast('未在活动时间内')
        return;
      }
      if (item.itemStatus !== 'CAN_BUY') return;
      const res = await this.checkCanBuy(item);
      console.log(res);
      if (!res) {
        this.getGoodList()
        return
      }
      // 服务端需要 做个转换
      const obj = JSON.parse(JSON.stringify(item))
      obj.itemId = obj.isFlashSale ? obj.itemId.split('-')[0] : obj.itemId

      this.payAction(obj, type)
    },
    checkCanBuy(info) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios.post(this.baseURLApp + '/anniversary/sale/checkCanBuy', {
        activityNo,
        userId: this.userId,
        hasWx: true,
        itemId: info.itemId
      }).then((r) => {
        this.$toast.clear()
        return r.code
      }).catch(() => {
        this.$toast.clear()
        return 0
      })
    },
    payAction(info, type) {
      let url = `/pages/webView/index?webUrl=${webUrl}&userId=1&token=1&coin=${info.goldCoin}`
      let payData = {
        subject: info.itemName,
        extendsJson: {
          appVersion: '2.1.11',
          seriesName: '月中福利日',
          // inviteUserId: this.inviteId,
        },
        totalAmount: info.salePrice,
        venueId: '31',
        goodsNum: type === 12 ? '1' : undefined,
        isApp: '1',
        type: type, // 礼品卡12  课包10
        userId: this.userId,
        token: this.token,
        extendsType: '4',
        mouldId: info.itemId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: info.itemName,
      }
      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      }
      appPay(payData, url);
      console.log(payData, url);
      if (this.appTypeStr !== 'mini') {
        appPaySuccess().then(() => {
          this.paySuccess();
          this.$toast('支付成功');
        })
      }
    },
    paySuccess() {
      console.log('suc')
      setTimeout(() => {
        this.getGoodList();
        this.getActivityTime();
      }, 500)
    },
    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: '618-2023',
            webUrl, //https://web.chaolu.com.cn/#/superdeer-activity/pri-punching-202201/index
          }),
        })
        .then((res) => {
          this.userDetail = res.data;
        });
    },
  },
  mounted() {
    this.bindHeaderScroll(window, 200, (scrollTop, morehalf) => {
      const topList = this.topList;
      if (!topList.length) { return }
      let index = 0;
      for (let i = topList.length - 1; i >= 0; i--) {
        if (scrollTop >= topList[i]) {
          index = i;
          break;
        }
      }
      if (index != this.tabKey) {
        this.tabKey = index;
      }
    });
  },
  async created() {
    console.log(this.$route.query);
    initBack();

    this.topList = [];
    this.inviteTeacherId = this.$route.query.teacherId || this.$route.query.inviteTeacherId;
    await this.$getAllInfo(['userId', 'cityId']);

    this.initQr();

    this.getBindInfo('fissionSale')
    this.getBindInfo('618Sale')

    this.getActivityTime()
    this.getGoodList();
    appPaySuccess().then(() => {//小程序购买成功提示
      this.paySuccess();
      this.$toast('支付成功');
    })
    wx.miniProgram.postMessage({
      data: {
        type: 'share',
        shareImage: this.shareParams.miniImage,
        shareTitle: this.shareParams.title,
        shareUrl: `/pages/webView/index?webUrl=${this.shareParams.path}`,
      },
    })
  }
}
</script>
<style lang="less" scoped>
.page-anniversary-2023 {

  background: #FB7A37;
  padding-bottom: 80px;
  div {
    box-sizing: border-box;
  }

  img {
    vertical-align: top;
    width: 100%;
  }

  .cc-content {
    width: 100%;
  }

  .headbg {
    position: relative;

    .rs {
      position: absolute;
      right: 0;
      top: 238px;
      width: 88px;

      .sec1,
      .sec2 {
        line-height: 56px;
        background: rgba(36, 40, 49, 0.3);
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        color: #fff;
        width: 100%;
        text-align: center;
        font-size: 24px;
        margin-bottom: 32px;
        padding-left: 6px;
      }
    }


  }

  .timer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #fff;
    line-height: 42px;
    margin: 20px 0 12px;
    height: 42px;

    .tbox {
      width: 44px;
      height: 40px;
      line-height: 42px;
      background: #fff;
      border-radius: 4px;
      font-size: 26px;
      font-weight: bold;
      margin: 0 12px;
      display: inline-block;
      text-align: center;
      color: #ED3C42;
    }

    .van-count-down {
      font-size: 24px;
      color: #fff;
    }
  }

  .invite-teacher {
    height: 104px;
    margin: 20px 32px 0;
    background: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_bg_invite.png);
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    padding: 0 36px;
    color: #EEEEEE;
    font-size: 22px;

    .invite-head {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      background: #f5f5f5;
      flex-shrink: 0;
      margin-right: 20px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .draw {
    height: 150px;
    margin: 0 36px;
    background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/banner-draw.png);
    background-size: 100% 100%;
  }

  .small-banner {
    width: 686px;
    margin: 30px 0 0;
  }

  .invite-box {
    height: 409px;
    margin: 17px 36px 60px;
    background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/invitebg.png);
    background-size: 100% 100%;

    .l1 {
      font-size: 32px;
      font-weight: bold;
      padding: 45px 32px 0;
      margin-bottom: 32px;
      display: flex;

      .love {
        width: 62px;
        height: 50px;
        margin-right: 14px;
      }

      .it {
        margin-top: 6px;
      }
    }

    .l2 {
      padding: 0 28px;
      display: flex;
      justify-content: space-between;

      .invite-model {
        width: 202px;
        height: 246px;
        background: #f5f5f5;
        background-size: 100% 100%;

        &.invite-private {
          background-image: url(https://img.chaolu.com.cn/ACT/anniversary-2023/invite-private.png);
        }

        &.invite-group {
          background-image: url(https://img.chaolu.com.cn/ACT/anniversary-2023/invite-group.png);
        }

        &.invite-micro {
          background-image: url(https://img.chaolu.com.cn/ACT/anniversary-2023/invite-micro.png);
        }
      }
    }
  }

  .goods-title {
    width: 427px;
    display: block;
    margin: 70px auto 12px;
  }

  .tofu {
    width: 100%;
    position: relative;
    padding: 24px 24px 8px;
    background-color: #FB7A37;
    .tofu-top {
      display: flex;
    }

    .tofu-bottom {
      display: flex;

    }

    .tm {
      flex: 1;
      margin-bottom: 16px;
      background: linear-gradient(180deg, #FFFFFF 0%, #FFE9E3 100%);
      border-radius: 12px;
      height: 100px;
      padding-left: 26px;
      color: #383E43;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      .it {
        font-weight: bold;
        font-size: 28px;
      }

      .ii {
        font-size: 24px;
        transform: scale(0.8);
        transform-origin: 0 50%;
        white-space: nowrap
      }
    }

    .mr {
      margin-right: 14px;
    }

    .mlr {
      margin: 0 16px;
    }

    .active {
      color: #9B270C;
      background: linear-gradient(180deg, #FFFED8 0%, #FFE293 100%);
    }
  }

  .titleline {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 29px 32px 36px;
    font-size: 20px;

    .classimg {
      height: 29px;
      width: auto;
    }

    .arrow {
      width: 21px;
    }

    .ss {
      margin-right: 10px;
    }
  }

  .end {
    text-align: center;
    color: #fff;
    font-size: 22px;
    position: relative;
    margin-top: 40px;

    &::after {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 60px;
      height: 1px;
      background: #fff;
      margin-left: 29px;
      margin-top: -2px;
    }

    &::before {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 60px;
      height: 1px;
      background: #fff;
      margin-right: 29px;
      margin-top: -2px;
    }
  }

  .goodsbox {
    padding: 0 32px;

    .csbar {
      height: 80px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.2);
      padding: 8px 6px;
      display: flex;
      margin-bottom: 46px;

      .barmodel {
        flex: 1;
        font-size: 30px;
        color: #fff;
        border-radius: 20px;
        font-weight: bold;
        text-align: center;
        line-height: 64px;
      }

      .active {
        background: linear-gradient(0deg, #FFFFFF 0%, #FFE9E3 100%);
        color: #F9624E;
      }
    }

    .basebg {
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.2);
      padding: 24px;
      margin-bottom: 40px;
      min-height: 280px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;

      &.pd1 {
        padding-top: 74px;
      }

      &.pd2 {
        padding-bottom: 8px;
      }

      .micro12 {
        width: 366px;
        height: 42px;
        display: block;
        margin: 0 auto 22px;
      }

      .invalid-time {
        color: #242831;
        font-size: 20px;
        margin-top: 16px;
        line-height: 1;
      }

      .label-line {
        white-space: nowrap;
        margin-top: 8px;

        .label-model {
          padding: 0 10px;
          height: 30px;
          background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
          border-radius: 4px;
          color: #B12E1D;
          font-weight: bold;
          font-size: 20px;
          margin-right: 10px;
          line-height: 30px;
          display: inline-flex;
          min-width: 70px;
          max-width: 100%;
          overflow: hidden;
        }
      }

      .price-line {
        margin-top: 8px;
        line-height: 1;

        .dl {
          font-size: 22px;
          color: #EE4832;
          font-weight: bold;
          margin-right: 4px;
        }

        .price {
          font-size: 32px;
          color: #EE4832;
          font-weight: bold;
          margin-right: 9px;
          position: relative;
          top: 2px;
        }

        .oldprice {
          text-decoration: line-through;
          color: #6C727A;
          font-size: 20px;
        }
      }

      .goodsmodel-shu {
        width: 312px;
        height: 449px;
        border-radius: 16px;
        background: #fff;
        padding: 24px;
        padding-bottom: 20px;
        position: relative;
        margin-bottom: 16px;
        overflow: hidden;

        .shu-img {
          height: 160px;
          background: #f5f5f5;
          border-radius: 16px;
          overflow: hidden;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .shu-name {
          font-weight: bold;
          color: #242831;
          font-size: 26px;
          margin-top: 23px;
          line-height: 1;
        }

        .shu-invalid-time {
          color: #242831;
          font-size: 20px;
          margin-top: 15px;
          line-height: 1;
        }



        .buy {
          // margin-top: 10px;
          position: absolute;
          left: 24px;
          right: 24px;
          bottom: 20px;
          height: 60px;
          background: linear-gradient(0deg, #F35947 0%, #FBA27B 100%);
          border-radius: 30px;
          color: #fff;
          font-weight: bold;
          text-align: center;
          line-height: 60px;
          font-size: 24px;
        }

        .gray {
          color: #696C6F;
          background: linear-gradient(0deg, #D7D7D7 0%, #CFD0D2 100%);
        }
      }

      .goodsmodel-heng {
        width: 100%;
        padding: 26px;
        background: #fff;
        display: flex;
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        margin-bottom: 20px;

        &:last-of-type {
          margin: 0;
        }



        .goods-img {
          width: 220px;
          height: 180px;
          border-radius: 16px;
          overflow: hidden;
          margin-right: 23px;

          &.big {
            width: 250px;
          }

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .goods-info {
          position: relative;
          overflow: hidden;
          flex: 1;

          .goods-name {
            font-size: 30px;
            color: #242831;
            font-weight: bold;
            line-height: 1;
          }

          .lastline {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;

            .sbuy {
              width: 120px;
              height: 50px;
              background: linear-gradient(0deg, #F35947 0%, #FBA27B 100%);
              border-radius: 25px;
              color: #fff;
              font-weight: bold;
              text-align: center;
              line-height: 50px;
              font-size: 24px;
            }

            .gray {
              color: #696C6F;
              background: linear-gradient(0deg, #D7D7D7 0%, #CFD0D2 100%);
            }
          }
        }
      }

      .send {
        position: absolute;
        left: 0;
        top: 0;
        border-bottom-right-radius: 16px;
        background: linear-gradient(90deg, #3889F0 0%, #A26BDC 99%);
        padding: 0 18px;
        line-height: 38px;
        font-size: 22px;
        color: #fff;
        font-weight: bold;
      }

      .coner {
        position: absolute;
        top: -10px;
        left: 0;
        height: 60px;
        width: 279px;
        background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/coverbg.png);
        background-size: 100% 100%;
        font-size: 30px;
        color: #68413C;
        font-weight: bold;
        text-align: center;
        text-indent: -20px;
        overflow: hidden;
        line-height: 60px;
      }
    }


  }

  .ds {
    height: 700px
  }

  .pop-rule {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    .pop-rule-title {
      height: 140px;
      color: #452C29;
      font-weight: bold;
      background: linear-gradient(180deg, #ffdbd9 0%, #fffeff 100%);
      font-size: 32px;
      text-align: center;
      line-height: 130px;
      position: relative;

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
        font-size: 28px;
      }
    }

    .pop-rule-content {
      color: #68413C;
      font-size: 26px;
      padding: 0 52px;
      max-height: 600px;
      overflow: auto;
      padding-bottom: 60px;

      div {
        margin-bottom: 30px;
        line-height: 1.6;
        white-space: pre-line;
      }
    }
  }

  .popcity {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    .buy-title {
      font-weight: bold;
      color: #242831;
      font-size: 32px;
      border-bottom: 1px solid #EEEEEE;
      height: 97px;
      line-height: 97px;
      padding: 0 32px;
      text-align: center;
      position: relative;
    }

    .buy-close {
      font-size: 36px;
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);
      color: #242831;
    }

    .cbox {
      padding: 37px 32px 0;
      border-bottom: 1px solid #eee
    }

    .buy-tips {
      font-size: 24px;
      color: #9AA1A9;
      margin: 18px 0 28px;
      line-height: 1;
    }

    .goods-line {
      display: flex;
      margin: 28px 0 60px;

      .goods-img {
        height: 160px;
        width: auto;
      }

      .goods-info {
        flex: 1;
        margin-left: 30px;

        .goods-name {
          font-size: 34px;
          color: #242831;
          font-weight: bold;
          line-height: 1;
          margin-top: 10px;
        }

        .time {
          font-size: 22px;
          color: #242831;
          margin: 20px 0;
          line-height: 1;
        }

        .label {
          display: flex;
          height: 30px;
          line-height: 32px;
          background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
          padding: 0 13px;
          margin-bottom: 15px;
          font-size: 20px;
          color: #B12E1D;
          font-weight: bold;
          border-radius: 4px;
          float: left;
        }
      }
    }

    .buy-title2 {
      color: #242831;
      font-size: 28px;
      font-weight: bold;
      line-height: 1;
    }

    .city-list {
      padding: 0 0 32px;

      .city-model {
        margin-top: 24px;
        height: 128px;
        background: #F5F5F5;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 32px;
        font-weight: bold;

        &:first-of-type {
          margin-top: 0
        }

        .checkbox {
          width: 32px;
          height: 32px;
          border: 1px solid #DDD;
          border-radius: 50%;
          margin-right: 8px;
        }
      }

      .city-active {
        border: 4px solid #242831;

        .checkbox {
          width: 48px;
          height: 48px;
          background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/spring2023/check.png);
          background-size: 100% 100%;
          border: none;
          margin: 0;
        }
      }
    }

    .next {
      line-height: 96px;
      background: #FFDE00;
      border-radius: 8px;
      margin: 34px 32px 0;
      font-weight: bold;
      text-align: center;
      margin-bottom: 34px;
    }
  }

  .popsure {
    width: 590px;
    border-radius: 12px;
    padding: 50px 40px;

    .sure-title {
      color: #242831;
      font-size: 36px;
      font-weight: bold;
      text-align: center;
    }

    .sure-content {
      color: #3C454E;
      font-size: 24px;
      line-height: 1.6;
      margin: 36px 0 48px;
      text-align: center;

      span {
        color: #F03C18;
      }
    }

    .sure-sub {
      border-radius: 8px;
      background: #FFDE00;
      color: #000;
      font-weight: bold;
      font-size: 32px;
      line-height: 96px;
      text-align: center;
    }
  }

  @bei: 1px;

  .canvascss {
    position: fixed;
    bottom: -200%;
    // top: 0;
    width: @bei*600;
    height: @bei*1066;
    margin: 0 auto;
    box-sizing: border-box;

    .canvascss_bg {
      width: 100%;
      height: 100%;
    }



    .canvascss_left {
      position: absolute;
      left: @bei*26;
      bottom: @bei*30;
      z-index: 1;
      width: @bei*100;
      height: @bei*100;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .canvascss_name {
      position: absolute;
      left: @bei*150;
      bottom: @bei*100;
      z-index: 1;
      color: #fff;
      font-weight: bold;
      font-size: @bei*28;
      width: @bei*252;
    }

    .canvascss_right {
      position: absolute;
      right: @bei*34;
      bottom: @bei*28;
      width: @bei*124;
      height: @bei*124;
      overflow: hidden;
      background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/border.png);
      background-size: 100% 100%;
      padding: @bei*12;

      img {
        width: 100%;
        height: 100%;
      }
    }

  }

  /deep/.canvas-box {
    padding-left: 40px !important;
    padding-right: 40px !important;
    box-sizing: border-box;

    .create-img {
      width: 100% !important;
    }
  }
}</style>
