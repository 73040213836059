<template>
  <div class="page-anniversary-2023">
    <headBar left-arrow :color="header_color" :title="header_title" :background="header_background"
      :opacity="header_opacity" :placeholder="false" @click-left="newAppBack" @setBarHeitht="setBarHeitht" />
    <div class="headbg">
      <img src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_banner.png" class="head-img" />
      <div class="rs">
        <div class="sec1" @click="shareActivity">分享</div>
        <div class="sec2" @click="ruleKey = undefined, showRule = true">规则</div>
      </div>
    </div>
    <div class="cc-content col-start-center">

      <div class="good-item col-start-center" v-for="item, index in goodList">
        <img :src="`https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_card_${index === 0 ? 2 : 1}.png`"
          alt="" class="small-banner">
        <div class="good-c">
          <div v-if="index === 0" class="row-between-center">
            <div class="quan-item col-start-center">
              <p :class="[(item.received && !item.voucherInfoList[0].received) || item.state === 4 ? 'grey' : '']"
                class="q1 row-center-center f24 fw6">{{ item.received ? item.voucherInfoList[0].received ? '已获得' : '未获得' :
                  '分享5人助力可得' }}</p>
              <div class="q2 fw6">
                <span class="f32">¥</span>
                <span class="f56">33</span>
              </div>
              <p class="q3 f20">满399元可用</p>
              <p class="q4 f20">下单指定商品，立享满减</p>
            </div>
            <div class="quan-item col-start-center">
              <p :class="[(item.received && !item.voucherInfoList[1].received) || item.state === 4 ? 'grey' : '']"
                class="q1 row-center-center f24 fw6">{{ item.received ? item.voucherInfoList[1].received ? '已获得' : '未获得' :
                  '新人加持可得' }}</p>
              <div class="q2 fw6">
                <span class="f32">¥</span>
                <span class="f56">66</span>
              </div>
              <p class="q3 f20">满399元可用</p>
              <p class="q4 f20">下单指定商品，立享满减</p>
            </div>
          </div>
          <div v-if="index === 1" class="row-between-center">
            <div class="quan-item col-start-center">
              <p :class="[(item.received && !item.voucherInfoList[0].received) || item.state === 4 ? 'grey' : '']"
                class="q1 row-center-center f24 fw6">{{ item.received ? item.voucherInfoList[0].received ? '已获得' : '未获得' :
                  '分享5人助力可得' }}</p>
              <div class="q2 fw6">
                <span class="f32">¥</span>
                <span class="f56">30</span>
              </div>
              <p class="q3 f20">满799元可用</p>
              <p class="q4 f20">下单指定商品，立享满减</p>
            </div>
            <div class="quan-item col-start-center">
              <p :class="[(item.received && !item.voucherInfoList[1].received) || item.state === 4 ? 'grey' : '']"
                class="q1 row-center-center f24 fw6">{{ item.received ? item.voucherInfoList[1].received ? '已获得' : '未获得' :
                  '新人加持可得' }}</p>
              <div class="q2 fw6">
                <span class="f32">¥</span>
                <span class="f56">80</span>
              </div>
              <p class="q3 f20">满799元可用</p>
              <p class="q4 f20">下单指定商品，立享满减</p>
            </div>
          </div>
          <div v-if="status === 'notin'" class="timer f24 fw6">
            <p>提前分享抢券，2023年6月15日 10:00 限量开售</p>
          </div>
          <div v-else-if="status === 'end'" class="timer f24 fw6">
            <p>活动已结束</p>
          </div>
          <div v-else class="timer f24 fw6">
            <p>距离结束</p>&nbsp;&nbsp;
            <van-count-down @finish="timeFinish" :time="time"></van-count-down>
          </div>
          <div class="row-between-center" style="padding: 14px 21px;">
            <div v-for="avator, index in item.assistanceRecordVOList" class="help-item" :key="index">
              <img :src="avator.assistanceUserHeadImg" alt="" class="avator">
              <img v-if="avator.hasNewUser" src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_new.png"
                alt="" class="new">
            </div>
          </div>
          <!-- 助力成功 已使用 -->
          <template v-if="item.state === 3 && index === 0">
            <div @click="buyCard(item, 10)" :class="['row-between-center', 'success', 'f24', 'p399']">
              您已参与过活动
            </div>
          </template>
          <template v-if="item.state === 3 && index === 1">
            <div @click="buyCard(item, 10)" :class="['row-between-center', 'success', 'f24', 'p799']">
              您已参与过活动
            </div>
          </template>
          <!-- 售罄 -->
          <template v-if="item.state === 4">
            <div class="row-center-center success gone f24">
              商品已抢空
            </div>
          </template>
          <!-- 助力成功 未使用 -->
          <template v-if="![3, 4].includes(item.state) && index === 0">
            <div @click="buyCard(item, 10)" v-if="item.received"
              :class="['row-between-center', 'success', 'f24', item.voucherInfoList[0].received ? '' : 'p333']">
              已有5人助力，获得{{ item.voucherInfoList[0].received ? 33 : 66 }}元券
            </div>
            <div v-else class="row-between-center" style="padding: 0 5px;">
              <img @click="buyCard(item, 10)" src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_b_1.png"
                alt="" class="button-item">
              <img @click="needHelp(item.itemId, 1705)"
                src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_b_7.png" alt="" class="button-item">
            </div>
          </template>
          <template v-if="![3, 4].includes(item.state) && index === 1">
            <div @click="buyCard(item, 10)" v-if="item.received"
              :class="['row-between-center', 'success', item.voucherInfoList[0].received ? 'p769' : 'p719', 'f24']">
              已有5人助力，获得{{ item.voucherInfoList[0].received ? 30 : 80 }}元券
            </div>
            <div v-else class="row-between-center" style="padding: 0 5px;">
              <img @click="buyCard(item, 10)" src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_b_2.png"
                alt="" class="button-item">
              <img @click="needHelp(item.itemId, 1703)"
                src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_b_7.png" alt="" class="button-item">
            </div>
          </template>


        </div>
      </div>

    </div>
    <van-popup v-model="showRule" position="bottom" safe-area-inset-bottom class="pop-rule">
      <div class="pop-rule-title">
        活动规则
        <van-icon name="cross" class="pop-rule-close" color="#fff" @click="showRule = false" />
      </div>
      <div class="pop-rule-content">
        1、用户可邀请好友为自己助力，邀请满5位好友即可享受助力价。若用户所邀请的5位好友中有1人或1人以上为未注册过超鹿的新用户，即可享受新人助力价。(新用户仅可助力1次，老用户最多可助力3次。)<br /><br />
        2、夏日专享瘦身包为不同产品组合包，课程券不可与礼品卡、其他优惠券、余额同时使用，同时不可转赠，不可提现及退款。<br /><br />
        3、以上两款商品库存有限，售完即止。同时每个ID每个商品限参与一次助力购分享活动。<br /><br />
        4、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。
      </div>
    </van-popup>

    <van-popup v-model="showSure" position="center" class="popsure">
      <div class="help-content  col-between-center">
        <img :src="helpInfo.userHeadPortrait" alt="" class="h-avator">
        <p class="h-name f30 fw6">{{ helpInfo.userName }}</p>
        <img :src="`https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_${helpText.img}.png`" alt=""
          class="h-desc">
        <p class="h-tip f24">{{ helpText.text }}</p>
        <div class="h-button row-center-center f42 fw6" @click="helpIt">{{ helpInfo.state === 0 ? '帮TA点一下' : '知道了' }}
        </div>
      </div>
      <van-icon class="h-close" size="32" color="#fff" name="close" @click="showSure = false" />

    </van-popup>

    <common-share :value="showShare" :shareParams="shareParams" @close="shareClose" @share-success="showShare = false"
      :shareItem="shareItem" :miniSharePoster="shareItem.length === 3 && appTypeStr === 'mini'">
      <template slot="createDom">
        <div class="canvascss">
          <img src="https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_poster.png" class="canvascss_bg" />

          <div class="canvascss_left">
            <img :src="userDetail.headImg" />
          </div>

          <div class="canvascss_name ellipsis">
            {{ userDetail.nickName }}
          </div>

          <div class="canvascss_right">
            <img v-if="userDetail.qrCode" :src="'data:image/png;base64,' +
              userDetail.qrCode
              " />
          </div>
        </div>
      </template>
    </common-share>

  </div>
</template>
<script>
import {
  newAppBack,
  initBack,
  appPay,
  appPaySuccess
} from '@/lib/appMethod';
import headBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
import headerMixin from '@/mixin/headerMixin';
import commonShare from '@/components/commonShare';
import wx from 'weixin-js-sdk';
const webUrl = `${window.location.origin}/#/temp-activity/15th-fission`;
const activityNo = '618Sale';
export default {
  components: {
    headBar,
    commonShare
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      barHeight: undefined,
      status: '',
      time: 0,

      shareParams: {
        title: '月中健身 福利大放送',
        miniImage:
          'https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_mini.png',
        path: `/pages/webView/index?webUrl=${webUrl}&userId=1&token=1`,
        miniProgramType:
          window.location.origin.indexOf('web.chaolu.com') > -1
            ? '0'
            : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      shareItem: ['posterfriend', 'postermessage', 'save'],

      userDetail: {}, //分享的用户信息
      showShare: false,
      showRule: false,
      goodList: [],


      ruleKey: undefined,

      showSure: false,

      assistanceUserId: '',
      helpInfo: {},

    }
  },
  computed: {
    helpText() {
      switch (this.helpInfo.state) {
        case 1:
          return { text: '', img: 'oo2' }
        case 2:
          return { text: '已助力3个好友，助力次数已耗完', img: 'oo3' }
        case 3:
          return { text: '', img: 'oo2' }
        default:
          return { text: '', img: 'oo1' }
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    newAppBack,

    setBarHeitht(h) {
      this.barHeight = h;
    },

    timeFinish() {
      this.getActivityInfo()
    },

    getActivityInfo() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios
        .post(this.baseURLApp + '/activity/fission/activityInfo', {
          hasWx: true,
          userId: this.userId,
        })
        .then((res) => {
          const d = res.data;
          this.goodList = d

          this.goodList.map((item, index) => {
            item.assistanceRecordVOList = item.assistanceRecordVOList ? item.assistanceRecordVOList.concat(new Array(5).fill({ assistanceUserHeadImg: 'https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_add_1.png' })).slice(0, 5) : new Array(5).fill({ assistanceUserHeadImg: 'https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_add_1.png' })
            if (index === 0) {
              item.salePrice = '399'
              item.itemName = '欢乐燃脂瘦身包'
            } else {
              item.salePrice = '799'
              item.itemName = '高效燃脂瘦身包'
            }
          })

          this.$toast.clear()
        });
    },
    getActivityTime() {
      this.$axios
        .post(this.baseURLApp + '/activity/getActivityTime', {
          activityNo,
          hasWx: true,
          userId: this.userId,
        })
        .then((res) => {
          const d = res.data;
          this.status = d.diffStartTime > 0 ? 'notin' : d.diffEndTime > 0 ? 'in' : 'end'
          this.time = d.diffStartTime || d.diffEndTime;
        });
    },
    getHelpInfo() {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.baseURLApp + '/activity/fission/assistanceInfo', {
            assistanceUserId: this.assistanceUserId,
            itemId: this.itemId,
            userId: this.userId,
            token: this.token
          })
          .then((res) => {
            resolve(res.data)
          });
      })
    },
    helpIt() {
      if (this.helpInfo.state !== 0) {
        this.showSure = false
        return
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })

      this.$axios
        .post(this.baseURLApp + '/activity/fission/assistance', {
          assistanceUserId: this.assistanceUserId,
          itemId: this.itemId,
          userId: this.userId,
          token: this.token
        })
        .then((res) => {
          const isNewUser = res.data.state == 2 ? true : false
          if (isNewUser) {
            wx.miniProgram.navigateTo({
              url: `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/new-people`,
            });
          }
          this.showSure = false
          this.$toast('助力成功')
        });
    },
    needHelp(id, countPointId) {
      this.countPoint(234, 324, countPointId, this.userId)
      this.shareParams.path = `/pages/webView/index?webUrl=${webUrl}&userId=1&token=1&assistanceUserId=${this.userId}&itemId=${id}`
      this.shareItem = ['minifriend']

      this.showShare = true
      this.miniShare()
    },
    shareActivity() {
      this.shareParams.path = `${webUrl}&userId=1&token=1`
      this.shareItem = ['posterfriend', 'postermessage', 'save']
      this.showShare = true
      this.miniShare()
    },
    shareClose() {
      this.shareParams.path = `/pages/webView/index?webUrl=${webUrl}&userId=1&token=1`
      this.showShare = false
    },
    async buyCard(item, type) {


      const res = await this.checkCanBuy(item);
      if (res.code === '0') {
        this.$toast(res.msg)
        return
      }

      // 服务端需要 做个转换
      const obj = JSON.parse(JSON.stringify(item))
      obj.itemId = obj.isFlashSale ? obj.itemId.split('-')[0] : obj.itemId

      this.payAction(obj, type)
    },
    checkCanBuy(info) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios.post(this.baseURLApp + '/anniversary/sale/checkCanBuy', {
        activityNo: 'fissionSale',
        userId: this.userId,
        hasWx: true,
        itemId: info.itemId
      }).then((r) => {
        this.$toast.clear()
        return r
      }).catch(() => {
        return r
      })
    },
    payAction(info, type) {
      let url = `/pages/webView/index?webUrl=${webUrl}&userId=1&token=1`
      let payData = {
        subject: info.itemName,
        extendsJson: {
          appVersion: '2.1.11',
          seriesName: '月中福利日',
          // inviteUserId: this.inviteId,
        },
        totalAmount: info.salePrice,
        venueId: '31',
        goodsNum: type === 12 ? '1' : undefined,
        isApp: '1',
        type: type, // 礼品卡12  课包10
        userId: this.userId,
        token: this.token,
        extendsType: '4',
        mouldId: info.itemId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: info.itemName,
      }
      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      }
      appPay(payData, url);
      console.log(payData, url);
      if (this.appTypeStr !== 'mini') {
        appPaySuccess().then(() => {
          this.paySuccess();
          this.$toast('支付成功');
        })
      }
    },
    paySuccess() {
      console.log('suc')
      setTimeout(() => {
        this.getActivityTime();
        this.getActivityInfo();
      }, 500)
    },
    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: '618-2023',
            webUrl, //https://web.chaolu.com.cn/#/superdeer-activity/pri-punching-202201/index
          }),
        })
        .then((res) => {
          this.userDetail = res.data;
        });
    },
    miniShare() {
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    }
  },

  async created() {
    initBack();

    await this.$getAllInfo(['userId']);

    this.assistanceUserId = this.$route.query.assistanceUserId
    this.itemId = this.$route.query.itemId

    if (this.assistanceUserId && this.assistanceUserId != this.userId) {
      let r = await this.getHelpInfo()

      if (r.state == 4) {
        this.$toast('活动已结束')
        setTimeout(() => {
          if (this.appTypeStr === 'mini') {
            wx.miniProgram.switchTab({
              url: `/pages/home/index`,
            })
          } else {
            this.newAppBack()
          }
        }, 2500);
        return
      }
      this.showSure = true
      this.helpInfo = r
    }

    this.initQr();
    this.getActivityTime()
    this.getActivityInfo()

    appPaySuccess().then(() => {//小程序购买成功提示
      this.paySuccess();
      this.$toast('支付成功');
    })
    this.miniShare()
  }
}
</script>
<style lang="less" scoped>
.page-anniversary-2023 {

  background: #FB7A37;
  padding-bottom: 80px;
  min-height: 100vh;

  div {
    box-sizing: border-box;
  }

  img {
    vertical-align: top;
    width: 100%;
  }

  .cc-content {
    width: 100%;
    padding: 0 32px;
    margin-top: -548px;
    position: relative;
  }

  .headbg {
    min-height: 892px;
    position: relative;

    .rs {
      position: absolute;
      right: 0;
      top: 238px;
      width: 88px;

      .sec1,
      .sec2 {
        line-height: 56px;
        background: rgba(36, 40, 49, 0.3);
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        color: #fff;
        width: 100%;
        text-align: center;
        font-size: 24px;
        margin-bottom: 32px;
        padding-left: 6px;
      }
    }


  }

  .good-item {
    margin: 0 0 40px;

    .good-c {
      width: 658px;
      padding: 29px 23px;
      border-radius: 0px 0px 20px 20px;
      background: linear-gradient(0deg, #FFED9D 0%, #FFDE9D 100%);

      .quan-item {
        width: 302px;
        height: 246px;
        background-image: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_q_1.png);
        background-size: contain;

        .q1 {
          width: 214px;
          height: 46px;
          margin: 6px 0 0;
          color: #fff;
          background: linear-gradient(90deg, #FF934C 0%, #FC686F 100%);
          border-radius: 0px 0px 16px 16px;

          &.grey {
            color: #fff;
            background: linear-gradient(90deg, #C4C2C7 0%, #C9C9C9 100%);
          }
        }

        .q2 {
          color: #ED4531;
          margin: 16px 0 0;
        }

        .q3 {
          color: #F09D93;
          margin: 4px 0 28px;
        }

        .q4 {}
      }

      .success {
        width: 594px;
        height: 80px;
        background-image: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_366_gou.png);
        background-size: 100% 100%;
        color: #FB7A37;
        padding: 5px 0 5px 26px;

        &.p333 {
          background-image: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_333_gou.png);
        }

        &.p399 {
          background-image: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_399_gou.png);
        }

        &.p719 {
          background-image: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_719_gou.png);
        }

        &.p769 {
          background-image: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_769_gou.png);
        }

        &.p799 {
          background-image: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_799_gou.png);
        }

        &.gone {
          background-image: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_bbb.png);
        }
      }

      .help-item {
        width: 80px;
        position: relative;

        .avator {
          width: 80px;
          border-radius: 50%;
        }

        .new {
          width: 34px;
          position: absolute;
          top: -8px;
          right: -10px;
        }
      }

      .button-item {
        width: 287px;
      }
    }
  }

  .timer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F84D07;
    margin: 12px 0 0;

    .van-count-down {
      display: flex;
      align-items: center;
      color: #F84D07;
    }
  }

  .invite-teacher {
    height: 104px;
    margin: 0 32px;
    background: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_bg_invite.png);
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    padding: 0 36px;
    color: #EEEEEE;
    font-size: 22px;
    margin-bottom: 36px;

    .invite-head {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      background: #f5f5f5;
      flex-shrink: 0;
      margin-right: 20px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .draw {
    height: 150px;
    margin: 0 36px;
    background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/banner-draw.png);
    background-size: 100% 100%;
  }

  .small-banner {
    width: 686px;
  }

  .invite-box {
    height: 409px;
    margin: 17px 36px 60px;
    background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/invitebg.png);
    background-size: 100% 100%;

    .l1 {
      font-size: 32px;
      font-weight: bold;
      padding: 45px 32px 0;
      margin-bottom: 32px;
      display: flex;

      .love {
        width: 62px;
        height: 50px;
        margin-right: 14px;
      }

      .it {
        margin-top: 6px;
      }
    }

    .l2 {
      padding: 0 28px;
      display: flex;
      justify-content: space-between;

      .invite-model {
        width: 202px;
        height: 246px;
        background: #f5f5f5;
        background-size: 100% 100%;

        &.invite-private {
          background-image: url(https://img.chaolu.com.cn/ACT/anniversary-2023/invite-private.png);
        }

        &.invite-group {
          background-image: url(https://img.chaolu.com.cn/ACT/anniversary-2023/invite-group.png);
        }

        &.invite-micro {
          background-image: url(https://img.chaolu.com.cn/ACT/anniversary-2023/invite-micro.png);
        }
      }
    }
  }

  .goods-title {
    width: 427px;
    display: block;
    margin: 70px auto 12px;
  }

  .tofu {
    width: 100%;
    position: relative;
    padding: 24px 24px 8px;
    background-color: #FB7A37;

    .tofu-top {
      display: flex;
    }

    .tofu-bottom {
      display: flex;

    }

    .tm {
      flex: 1;
      margin-bottom: 16px;
      background: linear-gradient(0deg, #FFFFFF 0%, #FFE9E3 100%);
      border-radius: 12px;
      height: 100px;
      padding-left: 26px;
      color: #383E43;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      .it {
        font-weight: bold;
        font-size: 28px;
      }

      .ii {
        font-size: 24px;
        transform: scale(0.8);
        transform-origin: 0 50%;
        white-space: nowrap
      }
    }

    .mr {
      margin-right: 14px;
    }

    .mlr {
      margin: 0 16px;
    }

    .active {
      color: #9B270C;
      background: linear-gradient(0deg, #FFFED8 0%, #FFE293 100%);
    }
  }

  .titleline {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 29px 32px 36px;
    font-size: 20px;

    .classimg {
      height: 29px;
      width: auto;
    }

    .arrow {
      width: 21px;
    }

    .ss {
      margin-right: 10px;
    }
  }

  .end {
    text-align: center;
    color: #fff;
    font-size: 22px;
    position: relative;
    margin-top: 40px;

    &::after {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 60px;
      height: 1px;
      background: #fff;
      margin-left: 29px;
      margin-top: -2px;
    }

    &::before {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 60px;
      height: 1px;
      background: #fff;
      margin-right: 29px;
      margin-top: -2px;
    }
  }

  .goodsbox {
    padding: 0 32px;

    .csbar {
      height: 80px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.2);
      padding: 8px 6px;
      display: flex;
      margin-bottom: 46px;

      .barmodel {
        flex: 1;
        font-size: 30px;
        color: #fff;
        border-radius: 20px;
        font-weight: bold;
        text-align: center;
        line-height: 64px;
      }

      .active {
        background: linear-gradient(0deg, #FFFFFF 0%, #FFE9E3 100%);
        color: #F9624E;
      }
    }

    .basebg {
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.2);
      padding: 24px;
      margin-bottom: 40px;
      min-height: 280px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;

      &.pd1 {
        padding-top: 74px;
      }

      &.pd2 {
        padding-bottom: 8px;
      }

      .micro12 {
        width: 366px;
        height: 42px;
        display: block;
        margin: 0 auto 22px;
      }

      .invalid-time {
        color: #242831;
        font-size: 20px;
        margin-top: 16px;
        line-height: 1;
      }

      .label-line {
        white-space: nowrap;
        margin-top: 8px;

        .label-model {
          padding: 0 10px;
          height: 30px;
          background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
          border-radius: 4px;
          color: #B12E1D;
          font-weight: bold;
          font-size: 20px;
          margin-right: 10px;
          line-height: 30px;
          display: inline-flex;
          min-width: 70px;
          max-width: 100%;
          overflow: hidden;
        }
      }

      .price-line {
        margin-top: 8px;
        line-height: 1;

        .dl {
          font-size: 22px;
          color: #EE4832;
          font-weight: bold;
          margin-right: 4px;
        }

        .price {
          font-size: 32px;
          color: #EE4832;
          font-weight: bold;
          margin-right: 9px;
          position: relative;
          top: 2px;
        }

        .oldprice {
          text-decoration: line-through;
          color: #6C727A;
          font-size: 20px;
        }
      }

      .goodsmodel-shu {
        width: 312px;
        height: 449px;
        border-radius: 16px;
        background: #fff;
        padding: 24px;
        padding-bottom: 20px;
        position: relative;
        margin-bottom: 16px;
        overflow: hidden;

        .shu-img {
          height: 160px;
          background: #f5f5f5;
          border-radius: 16px;
          overflow: hidden;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .shu-name {
          font-weight: bold;
          color: #242831;
          font-size: 26px;
          margin-top: 23px;
          line-height: 1;
        }

        .shu-invalid-time {
          color: #242831;
          font-size: 20px;
          margin-top: 15px;
          line-height: 1;
        }



        .buy {
          // margin-top: 10px;
          position: absolute;
          left: 24px;
          right: 24px;
          bottom: 20px;
          height: 60px;
          background: linear-gradient(0deg, #F35947 0%, #FBA27B 100%);
          border-radius: 30px;
          color: #fff;
          font-weight: bold;
          text-align: center;
          line-height: 60px;
          font-size: 24px;
        }

        .gray {
          color: #696C6F;
          background: linear-gradient(0deg, #D7D7D7 0%, #CFD0D2 100%);
        }
      }

      .goodsmodel-heng {
        width: 100%;
        padding: 26px;
        background: #fff;
        display: flex;
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        margin-bottom: 20px;

        &:last-of-type {
          margin: 0;
        }



        .goods-img {
          width: 220px;
          height: 180px;
          border-radius: 16px;
          overflow: hidden;
          margin-right: 23px;

          &.big {
            width: 250px;
          }

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .goods-info {
          position: relative;
          overflow: hidden;
          flex: 1;

          .goods-name {
            font-size: 30px;
            color: #242831;
            font-weight: bold;
            line-height: 1;
          }

          .lastline {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;

            .sbuy {
              width: 120px;
              height: 50px;
              background: linear-gradient(0deg, #F35947 0%, #FBA27B 100%);
              border-radius: 25px;
              color: #fff;
              font-weight: bold;
              text-align: center;
              line-height: 50px;
              font-size: 24px;
            }

            .gray {
              color: #696C6F;
              background: linear-gradient(0deg, #D7D7D7 0%, #CFD0D2 100%);
            }
          }
        }
      }

      .send {
        position: absolute;
        left: 0;
        top: 0;
        border-bottom-right-radius: 16px;
        background: linear-gradient(90deg, #3889F0 0%, #A26BDC 99%);
        padding: 0 18px;
        line-height: 38px;
        font-size: 22px;
        color: #fff;
        font-weight: bold;
      }

      .coner {
        position: absolute;
        top: -10px;
        left: 0;
        height: 60px;
        width: 279px;
        background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/coverbg.png);
        background-size: 100% 100%;
        font-size: 30px;
        color: #68413C;
        font-weight: bold;
        text-align: center;
        text-indent: -20px;
        overflow: hidden;
        line-height: 60px;
      }
    }


  }

  .ds {
    height: 700px
  }

  .pop-rule {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    .pop-rule-title {
      height: 140px;
      color: #452C29;
      font-weight: bold;
      background: linear-gradient(180deg, #ffdbd9 0%, #fffeff 100%);
      font-size: 32px;
      text-align: center;
      line-height: 130px;
      position: relative;

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
        font-size: 28px;
      }
    }

    .pop-rule-content {
      color: #68413C;
      font-size: 26px;
      padding: 0 52px;
      max-height: 600px;
      overflow: auto;
      padding-bottom: 60px;
      line-height: 1.6;
      white-space: pre-line;
    }
  }

  .popcity {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    .buy-title {
      font-weight: bold;
      color: #242831;
      font-size: 32px;
      border-bottom: 1px solid #EEEEEE;
      height: 97px;
      line-height: 97px;
      padding: 0 32px;
      text-align: center;
      position: relative;
    }

    .buy-close {
      font-size: 36px;
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);
      color: #242831;
    }

    .cbox {
      padding: 37px 32px 0;
      border-bottom: 1px solid #eee
    }

    .buy-tips {
      font-size: 24px;
      color: #9AA1A9;
      margin: 18px 0 28px;
      line-height: 1;
    }

    .goods-line {
      display: flex;
      margin: 28px 0 60px;

      .goods-img {
        height: 160px;
        width: auto;
      }

      .goods-info {
        flex: 1;
        margin-left: 30px;

        .goods-name {
          font-size: 34px;
          color: #242831;
          font-weight: bold;
          line-height: 1;
          margin-top: 10px;
        }

        .time {
          font-size: 22px;
          color: #242831;
          margin: 20px 0;
          line-height: 1;
        }

        .label {
          display: flex;
          height: 30px;
          line-height: 32px;
          background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
          padding: 0 13px;
          margin-bottom: 15px;
          font-size: 20px;
          color: #B12E1D;
          font-weight: bold;
          border-radius: 4px;
          float: left;
        }
      }
    }

    .buy-title2 {
      color: #242831;
      font-size: 28px;
      font-weight: bold;
      line-height: 1;
    }

    .city-list {
      padding: 0 0 32px;

      .city-model {
        margin-top: 24px;
        height: 128px;
        background: #F5F5F5;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 32px;
        font-weight: bold;

        &:first-of-type {
          margin-top: 0
        }

        .checkbox {
          width: 32px;
          height: 32px;
          border: 1px solid #DDD;
          border-radius: 50%;
          margin-right: 8px;
        }
      }

      .city-active {
        border: 4px solid #242831;

        .checkbox {
          width: 48px;
          height: 48px;
          background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/spring2023/check.png);
          background-size: 100% 100%;
          border: none;
          margin: 0;
        }
      }
    }

    .next {
      line-height: 96px;
      background: #FFDE00;
      border-radius: 8px;
      margin: 34px 32px 0;
      font-weight: bold;
      text-align: center;
      margin-bottom: 34px;
    }
  }

  .popsure {
    width: 630px;
    background-color: transparent;

    .help-content {
      height: 790px;
      padding: 315px 0 50px;
      background-image: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_get.png);
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-color: #FFFAEE;
      position: relative;
      border-radius: 40px;
    }

    .h-avator {
      width: 122px;
      height: 122px;
      border-radius: 50%;
    }

    .h-name {
      margin: -12px 0 0;
    }

    .h-desc {
      width: auto;
      height: 40px;
      margin: 12px 0 0;
    }

    .h-tip {
      margin: -12px 0 18px;
    }

    .h-button {
      width: 530px;
      height: 90px;
      background-image: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/lie_button12.png);
      background-size: 100% auto;
      color: #fff;

    }

    .h-close {
      display: block;
      margin: 60px auto;
      text-align: center;
    }

  }

  @bei: 1px;

  .canvascss {
    position: fixed;
    bottom: -200%;
    // top: 0;
    width: @bei*600;
    height: @bei*1066;
    margin: 0 auto;
    box-sizing: border-box;

    .canvascss_bg {
      width: 100%;
      height: 100%;
    }



    .canvascss_left {
      position: absolute;
      left: @bei*26;
      bottom: @bei*30;
      z-index: 1;
      width: @bei*100;
      height: @bei*100;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .canvascss_name {
      position: absolute;
      left: @bei*150;
      bottom: @bei*108;
      z-index: 1;
      color: #fff;
      font-weight: bold;
      font-size: @bei*28;
      width: @bei*252;
    }

    .canvascss_right {
      position: absolute;
      right: @bei*34;
      bottom: @bei*28;
      width: @bei*124;
      height: @bei*124;
      overflow: hidden;
      background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/border.png);
      background-size: 100% 100%;
      padding: @bei*12;

      img {
        width: 100%;
        height: 100%;
      }
    }

  }

  /deep/.canvas-box {
    padding-left: 40px !important;
    padding-right: 40px !important;
    box-sizing: border-box;

    .create-img {
      width: 100% !important;
    }
  }
}</style>
